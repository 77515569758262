require("jquery")
import Cookies from 'js-cookie'

const cc_name = '_cookie_consent_'  + $('meta[name=lang]').prop('content');
const cc_value = 'accepted';



function showCCban(){
    $('#cc_banner').removeClass('d-none');
}

function closeCCban(){
    $('#cc_banner').addClass('d-none');
}

function acceptCC(){
    saveCConsent();
    closeCCban();
}

function saveCConsent(){
    if(getCCresult() != cc_value){
        Cookies.set(cc_name, cc_value, { expires: 365 });
    }
}

function getCCresult(){
    return Cookies.get(cc_name);
}


$(document).ready(function() {
    $('#cc_link').click(function(){ showCCban(); return false; });

    document.getElementById ("btncc").addEventListener ("click", acceptCC, false);
        const cc_content = Cookies.get(cc_name);
         if(getCCresult() != cc_value){
         showCCban();
    }
});

